/**
 * Created by Max Gornostayev 2/28/22
 *
 * MODEL - User model
 */
import API from '../api/API';
import { ALLOW_DEPARTMENTS_PRODUCTS_RESET } from '../config';
import { IUser } from '../interfaces/IModels';
import { CrmAddress } from '../interfaces/IN8N';
import { toastManager } from '../lib/ToastManager';

class User implements IUser {
  firstName?: string;

  lastName?: string;

  phone?: string;

  isActive?: boolean;

  address?: CrmAddress;

  crmId?: string;

  email?: string;

  position?: string;

  photo?: string;

  departments?: Array<number>;

  constructor(token?: string) {
    this.loadUser(token);
  }

  loadUser = async (token?: string) => {
    if (token) {
      try {
        const arrayToken = token.split('.');
        const payload = JSON.parse(atob(arrayToken[1]));
        const user = await API.parse.getEmployee(payload.id);

        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.phone = user.phone;
        this.isActive = user.isActive;
        this.address = user.address;
        this.crmId = user.crmId;
        this.email = user.email;
        this.position = user.position;
        this.photo = user.photo;
        this.departments = user.departments;
      } catch (e) {
        toastManager.showError('User nicht vorhanden. Bitte wende dich an die IT.');
      }
    }
  };

  getPermissions() {
    return {
      productsResetPermission: ALLOW_DEPARTMENTS_PRODUCTS_RESET.some((per) => this.departments?.includes(per)),
      shouldShowOfferUnitPrices: ALLOW_DEPARTMENTS_PRODUCTS_RESET.some((per) => this.departments?.includes(per)),
    };
  }
}

export default User;
